import { CurrentUser } from '@src/app/shared/models/user';
import { updateCurrentRsUser } from './user.actions';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

export interface UserState {
  currentRsUser: CurrentUser | null;
}

export const initialUserState: UserState = {
  currentRsUser: null
};

export const userReducer = createReducer(
  initialUserState,
  on(updateCurrentRsUser, (state, { user }) =>
    produce(state, draft => {
      draft.currentRsUser = user;
    })),
);
