import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';

export const FRONT_PLATE_LEASING_COMPANY_NUMBERS = ['799110', '801885', '799230', '799150', '799130'];

export const FrontPlateGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const currentUser = store.selectSignal(selectCurrentRsUser)()!;

  const hasAccess = FRONT_PLATE_LEASING_COMPANY_NUMBERS.includes(currentUser.rsNumber);

  return hasAccess ? true : router.parseUrl('/rs-number');
};
