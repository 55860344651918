import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { DefaultDeliveryInformation } from '@app/registrations-v2/shared/models/registration';
import { RegistrationDeliveryAddress } from '@shared/models/delivery-address';
import { DeliveryAddressesLabel } from '@shared/types/custom-types';
import { Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { RegistrationDetailsOverviewService } from '@app/registrations-v2/shared/services/registration-details-overview/registration-details-overview.service';

interface DeliveryOptions {
  label: DeliveryAddressesLabel;
  address?: RegistrationDeliveryAddress | null;
}

enum DefaultAddressSelection {
  'DC' = 'DISTRIBUTION_CENTER',
  'LC' = 'LEASING_COMPANY',
  'OTHER' = 'OTHER'
}

/**
 * @deprecated
 *
 * To be removed when front-plates modules is completely migrated under Distribution Center.
 */
@Component({
  selector: 'ui-renta-delivery-address-form',
  templateUrl: './selection-delivery-address-form.component.html',
  styleUrls: ['./selection-delivery-address-form.component.scss']
})
export class SelectionDeliveryAddressFormComponent implements OnInit {

  @Input() public frontPlateForm!: UntypedFormGroup;
  @Output() public isLoading: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  public preventDeliveryAddressEdition: boolean = false;
  public formDeliveryAddressDropDown: DeliveryOptions[] = [];
  public selectedDeliveryAddress?: DeliveryAddressesLabel;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public registrationService: RegistrationDetailsOverviewService
  ) {
  }

  public ngOnInit(): void {
    this.prefillDeliveryForm();
    this.handlersOnFormFieldsValueChange();
  }

  public prefillDeliveryAddresses(
    option: DeliveryOptions
  ): void {

    this.preventDeliveryAddressEdition = option.label !== 'OTHER';

    const address: RegistrationDeliveryAddress | undefined | null = option.address;

    this.frontPlateForm.patchValue({
      boxNumber: address?.boxNumber,
      city: address?.city,
      firstName: address?.firstName,
      houseNumber: address?.houseNumber,
      lastNameOrCompanyName: address?.lastNameOrCompanyName,
      postalCode: address?.postalCode,
      street: address?.street,
    });
  }

  /** Init all form fields subscribers that trigger appropriate actions */
  private handlersOnFormFieldsValueChange(): void {

    // keep changes to delivery address if deliveryAddressType = OTHER
    this.frontPlateForm!
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newAddress: RegistrationDeliveryAddress) => {
        setTimeout(() => {
          if (this.selectedDeliveryAddress === 'OTHER' && this.formDeliveryAddressDropDown.length > 0) {
            const option = this.formDeliveryAddressDropDown.find((deliveryOption) => deliveryOption.label === 'OTHER')!;
            option.address = newAddress;
          }
        }, 0);
      });
  }

  private prefillDeliveryForm(): void {
    this.registrationService.getDefaultDeliveryInformation().pipe(
      take(1),
      finalize(() => this.isLoading.emit(false))
    ).subscribe((defaultDeliveryInformation) => {
      this.setDefaultDeliveryAddressesOptions(
        defaultDeliveryInformation?.leasingCompanyDeliveryAddressesJson,
      );
    });
  }

  /** Set default delivery addresses options.
   *
   * Radio buttons:
   * LEASING_COMPANY - DISTRIBUTION_CENTER - OTHER
   */
  private setDefaultDeliveryAddressesOptions(
    defaultAddresses: DefaultDeliveryInformation['leasingCompanyDeliveryAddressesJson'],
  ): void {

    this.formDeliveryAddressDropDown = [];

    if (!defaultAddresses) {
      return;
    }

    // DC
    if (defaultAddresses?.distributionCenterAddress) {
      this.formDeliveryAddressDropDown.push({
        label: 'DISTRIBUTION_CENTER',
        address: defaultAddresses?.distributionCenterAddress,
      });
    }

    // LC
    if (defaultAddresses?.leasingCompanyAddress) {
      this.formDeliveryAddressDropDown.push({
        label: 'LEASING_COMPANY',
        address: defaultAddresses?.leasingCompanyAddress
      });
    }

    // OTHER
    if (this.formDeliveryAddressDropDown.length > 0) {
      this.formDeliveryAddressDropDown.push({ label: 'OTHER', address: null });
    }

    let defaultSelectedAddress : RegistrationDeliveryAddress | undefined;

    switch (defaultAddresses.defaultDelivery) {
      case 'DC':
        defaultSelectedAddress = defaultAddresses?.distributionCenterAddress;
        break;

      case 'LC':
        defaultSelectedAddress = defaultAddresses?.leasingCompanyAddress;
        break;
    }
    // Select Radio option

    this.selectedDeliveryAddress = defaultAddresses.defaultDelivery === 'SUPPLIER' ? 'OTHER' : DefaultAddressSelection[defaultAddresses.defaultDelivery];

    if (defaultSelectedAddress) {
      this.frontPlateForm.patchValue(defaultSelectedAddress);
    }

    // Prevent delivery address edition
    this.preventDeliveryAddressEdition = this.selectedDeliveryAddress !== 'OTHER';
  }
}
