<div [formGroup]="frontPlateForm">
  <!-- Delivery address -->
  <h3 class="mt-30px pb-15px">{{ 'DELIVERY_ADDRESS' | translate }}</h3>

  <!-- Address type -->
  <h5 class="label mb-10px rs-font--primary-light">{{ 'SAVED_ADDRESSES' | translate }}</h5>

  <div class="row align-items-center mb-30px">
    <!-- Delivery address drop down -->
    <div class="col-12">
      <mat-radio-group
        #radioGroup="matRadioGroup"
        [(ngModel)]="selectedDeliveryAddress"
        [ngModelOptions]="{standalone: true}"
      >
        <mat-radio-button
          (change)="prefillDeliveryAddresses(options)"
          *ngFor="let options of formDeliveryAddressDropDown"
          [value]="options.label"
          class="me-10px"
        >
          {{ options.label | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <!-- Address -->
  <div
    [class.read-only]="preventDeliveryAddressEdition"
    class="row row-cols-2 align-items-center position-relative"
    id="delivery-address-container"
  >
    <!-- Last name or company name -->
    <div class="col-6">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <mat-label>{{ 'LAST_NAME_OR_COMPANY_NAME' | translate }}</mat-label>
        <input
          formControlName="lastNameOrCompanyName"
          matInput
        >
        <mat-error *ngIf="frontPlateForm.get('lastNameOrCompanyName')?.hasError('required')">
          {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- firstName -->
    <div class="col-6">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
        <input
          formControlName="firstName"
          matInput
        >
      </mat-form-field>
    </div>

    <!-- street -->
    <div class="col-12">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <mat-label>{{ 'STREET' | translate }}</mat-label>
        <input
          formControlName="street"
          matInput
        >
        <mat-error *ngIf="frontPlateForm.get('street')?.hasError('required')">
          {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- houseNumber -->
    <div class="col-6">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <mat-label>{{ 'HOUSE_NUMBER' | translate }}</mat-label>
        <input
          formControlName="houseNumber"
          matInput
        >
        <mat-error *ngIf="frontPlateForm.get('houseNumber')?.hasError('required')">
          {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- boxNumber -->
    <div class="col-6">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <mat-label>{{ 'BOX_NUMBER' | translate }}</mat-label>
        <input
          formControlName="boxNumber"
          matInput
        >
      </mat-form-field>
    </div>

    <!-- postalCode -->
    <div class="col-6">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <mat-label>{{ 'POSTAL_CODE' | translate }}</mat-label>
        <input
          formControlName="postalCode"
          matInput
          rsIntegerInput
        >
        <mat-error *ngIf="frontPlateForm.get('postalCode')?.hasError('required')">
          {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="frontPlateForm.get('postalCode')?.hasError('minlength')">
          {{ 'FORM_ERRORS.MIN_LENGTH' | translate: { chars: '4' } }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- city -->
    <div class="col-6">
      <mat-form-field
        class="w-100"
        rsAddClearFormFieldButton
      >
        <input
          [placeholder]="('CITY' | translate)"
          formControlName="city"
          matInput
        >
        <mat-error *ngIf="frontPlateForm.get('city')?.hasError('required')">
          {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>