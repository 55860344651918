import { RsBackEndError, UUID } from '@lib/rs-ngx';
import { createAction, props } from '@ngrx/store';
import { InsuranceBroker } from '@shared/models/insurance-brokers';

export const saveRegistrationID = createAction('[Registrations] Save selected registration ID and set existingRegistration to true', props<{
  id: UUID
}>());
export const saveRegistrationOrderID = createAction('[Registrations] Save selected order ID if no linked registration ID and set existingRegistration to false', props<{
  id: UUID
}>());
export const clearExistingRegistration = createAction('[Registrations] Clear retrieveRegistrationData');
export const setBackToOverviewLink = createAction('[Registrations] Set back link to active/inactive reg overview link on registration details-section', props<{
  link: 'registrations/active-registrations-overview' | 'registrations/inactive-registrations-overview'
}>());

export const loadInsuranceBrokers = createAction('[Registrations] Load Insurance Brokers');
export const loadInsuranceBrokersSuccess = createAction('[Registrations] Load Insurance Brokers Success', props<{
  insuranceBrokers: InsuranceBroker[]
}>());
export const loadInsuranceBrokersFailure = createAction('[Registrations] Load Insurance Brokers Failure', props<{
  error: RsBackEndError
}>());
