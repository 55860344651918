import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RentaNgxLibModule } from '@lib/rs-ngx';
import { CustomMaterialModule } from './custom-material-module/custom-material-module.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
  SelectionDeliveryAddressFormComponent
} from './components/selection-delivery-address-form/selection-delivery-address-form.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  declarations: [SelectionDeliveryAddressFormComponent],
  imports: [
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RentaNgxLibModule,
    CommonModule,
    ContactInfoComponent
  ],
  exports: [
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RentaNgxLibModule,
    CommonModule,
    SelectionDeliveryAddressFormComponent,
    ContactInfoComponent,
  ],
  providers: [provideEnvironmentNgxMask(),]
})
export class SharedModules {
}
