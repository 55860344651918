import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { MenuItem } from '@lib/rs-ngx';
import { CurrentUser } from '@shared/models/user';
import * as Sentry from '@sentry/angular-ivy';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FRONT_PLATE_LEASING_COMPANY_NUMBERS } from '@shared/guards/front-plate/front-plate.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public menuItems: MenuItem[] = [];
  public readonly topBarEnvironment: 'dev' | 'tst' | 'acc' | 'prd';
  public readonly topBarCdn: string;
  private destroyRef$ = inject(DestroyRef);
  private currentUser?: CurrentUser;

  public constructor(
    private readonly store: Store
  ) {
    if ('Cypress' in window) {
      window['store'] = this.store;
    }
    this.topBarEnvironment = environment.topBarEnvironment;
    this.topBarCdn = environment.topBarCdn;
  }

  public onRouteChange(url: string): void {
    if (url === '/rs-number') {
      this.menuItems = [];
    } else {
      this.setTopBarMenu();
    }
  }

  public ngOnInit(): void {
    this.store
      .select(selectCurrentRsUser)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe((currentUser) => {
        if (currentUser) {
          this.currentUser = currentUser;
          Sentry.configureScope((scope): void => {
            scope.setUser({
              rsUser: {
                companyName: currentUser.companyName,
                loginString: currentUser.loginString,
                userRole: currentUser.userRole,
                id: currentUser.id
              }
            });
          });
        }
      });
  }

  public onLogout(): void {
    localStorage.clear();
  }

  private setTopBarMenu(): void {
    // Do not reset if already set
    if (this.menuItems === undefined || this.menuItems?.length === 0) {
      this.menuItems = [];
      this.menuItems.push(this.getManagementMenu());
      this.menuItems.push(this.getUsersMenu());
      this.menuItems.push(this.getBatchesMenu());
      this.menuItems.push(this.getOrdersMenu());
      this.menuItems.push(this.getRegistrationMenu());
      this.menuItems.push(this.getDistributionCenterMenu());
      this.menuItems.push(this.getAdministrationMenu());
      this.menuItems.push(this.getRsNumberMenu());
    }
  }

  private getBatchesMenu(): MenuItem {
    return {
      id: 'batch',
      label: 'BATCH',
      display: this.currentUser?.userRole === 'ADMIN',
      url: 'admin/batch'
    };
  }

  private getManagementMenu(): MenuItem {
    return {
      id: 'management',
      label: 'MANAGEMENT',
      display: this.currentUser?.userRole === 'ADMIN',
      subMenuItems: [
        {
          url: 'admin/suppliers',
          id: 'admin-suppliers',
          label: 'SUPPLIERS'
        },
        {
          url: 'admin/leasing-companies',
          id: 'admin-leasing-companies',
          label: 'LEASING_COMPANIES'
        },
        {
          url: 'admin/brokers',
          id: 'admin-brokers',
          label: 'BROKERS'
        }
      ]
    };
  }

  private getUsersMenu(): MenuItem {
    return {
      id: 'admin-users',
      label: 'USERS',
      display: this.currentUser?.userRole === 'ADMIN',
      url: 'admin/users'
    };
  }

  private getOrdersMenu(): MenuItem {
    const showVehicles = !(this.currentUser?.userRole === 'DEALER' && this.currentUser?.access.wallBox);
    const showEVProducts = this.currentUser?.access?.wallBox;

    return {
      id: 'active-orders-overview',
      label: 'ORDERS',
      url: '/orders/active-orders-overview',
      baseMenuUrl: ['orders', 'ev-products'],
      display: this.currentUser?.userRole != 'ADMIN',
      subMenuItems: [
        {
          url: '/orders/active-orders-overview',
          id: 'orders-active-orders-overview',
          label: 'VEHICLES',
          display: showVehicles && showEVProducts
        },
        {
          url: 'ev-products',
          id: 'ev-products',
          display: showEVProducts && showVehicles,
          label: 'EV_PRODUCTS'
        },
      ]
    };
  }

  private getRegistrationMenu(): MenuItem {
    const registrationSubItem = this.currentUser?.access?.distributionCenterVersion === 'VERSION_1' ? {
      id: 'registrations-active-registrations-overview',
      url: 'registrations/active-registrations-overview',
      display: this.currentUser?.access?.registration,
      label: 'REGISTRATIONS'

    } : {
      id: 'registrations-overview-active',
      url: 'registrations/active',
      display: this.currentUser?.access?.registration,
      label: 'REGISTRATIONS'
    };


    return {
      id: 'active-registrations-overview',
      label: 'REGISTRATION',
      display: this.currentUser?.access?.registration || this.currentUser?.access?.distributionCenter,
      baseMenuUrl: ['registrations', 'front-plates'],
      subMenuItems: [
        registrationSubItem,
        {
          url: 'front-plates',
          display: this.shouldDisplayFrontPlatesForCurrentUser(),
          id: 'front-plates',
          label: 'FRONT_PLATES'
        },
      ]
    };
  }

  private getDistributionCenterMenu(): MenuItem {
    const isLeasingCompanyWithAuthorization = this.currentUser!.userRole === 'LEASING_COMPANY_WITH_AUTHORIZATION';
    const isLeasingCompany = this.currentUser!.userRole === 'LEASING_COMPANY';
    const hasAccessToDistributionCenter = this.currentUser!.access.distributionCenter || false;
    const canDisplayDistributionCenterMenu = this.currentUser!.access.distributionCenterVersion === 'VERSION_1' ? isLeasingCompanyWithAuthorization : true;

    return {
      id: 'distribution-center',
      label: 'DISTRIBUTION_CENTER',
      display: canDisplayDistributionCenterMenu && hasAccessToDistributionCenter,
      subMenuItems: [
        {
          id: 'distribution-center-overview-items',
          url: 'distribution-center/items',
          label: 'ITEMS',
          display: isLeasingCompanyWithAuthorization
        },
        {
          id: 'distribution-center-overview-packages',
          url: 'distribution-center/packages',
          label: 'PACKAGES',
          display: isLeasingCompanyWithAuthorization
        },
        {
          id: 'distribution-center-overview-packages-orders',
          url: 'distribution-center/package-orders',
          label: 'PACKAGE-ORDERS',
          display: this.currentUser?.access.distributionCenterVersion === 'VERSION_2' && (isLeasingCompany || isLeasingCompanyWithAuthorization)
        }
      ],
    };
  }

  private shouldDisplayFrontPlatesForCurrentUser(): boolean {
    return FRONT_PLATE_LEASING_COMPANY_NUMBERS.some(lcNumber => this.currentUser?.loginString.startsWith(lcNumber));
  }

  private getAdministrationMenu(): MenuItem {
    return {
      url: 'administration',
      id: 'administration',
      label: 'ADMINISTRATION',
      display: this.currentUser && this.currentUser?.userRole === 'LEASING_COMPANY_WITH_AUTHORIZATION',
    };
  }

  private getRsNumberMenu(): MenuItem {
    return {
      url: 'rs-number',
      id: 'rs-number',
      label: 'SWITCH_ACCOUNT',
      display: this.currentUser?.hasAccessToRsNumberSelectionPage,
      labelSuffix: '(' + this.currentUser?.loginString + ')'
    };
  }
}
