import { environment } from '@environments/environment';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomMatPaginatorIntlService } from './services/mat-paginator-intl';
import { coreInterceptorProviders } from './http-interceptors';
import { coreServiceProviders } from './services';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { errorHandlerProviders } from './error-handlers';
import { RsCoreModule, ScrollToModule, UserInfoCookieAppName } from '@lib/rs-ngx';
import { coreReducer, metaReducers } from '@app/core/state/core.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RegistrationsEffects } from '@app/core/state/registrations/registrations.effects';
import { OrdTitleStrategy } from '@shared/providers/ord-title-strategy';
import { TitleStrategy } from '@angular/router';

registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);

@NgModule({ imports: [
  BrowserAnimationsModule,
  BrowserModule,
  ScrollToModule.forRoot(),
  StoreModule.forRoot(coreReducer, { metaReducers }),
  EffectsModule.forRoot([RegistrationsEffects]),
  StoreDevtoolsModule.instrument({ maxAge: 25,
    logOnly: environment.production,
    connectInZone: true }),
  RsCoreModule.forRoot({
    appName: UserInfoCookieAppName.ORD,
    environment
  })
],
providers: [
  errorHandlerProviders,
  coreServiceProviders,
  coreInterceptorProviders,
  { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService },
  { provide: TitleStrategy, useClass: OrdTitleStrategy },
  provideHttpClient(withInterceptorsFromDi()),
] })
export class CoreModule {
  public constructor(
        @Optional() @SkipSelf() coreModule?: CoreModule
  ) {
    if (coreModule) {
      throw new Error('CoreModule is already loaded. Import it only once!');
    }
  }
}
