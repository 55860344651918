import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModules } from '@shared/shared.module';
import { NgModule } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { RsLoaderComponent } from '@rs-shared-lib/components/rs-loader/components/rs-loader.component';


@NgModule({
  declarations: [AppComponent],
  imports: [
    RsLoaderComponent,
    AppRoutingModule,
    SharedModules,
    CoreModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
