import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { Store } from '@ngrx/store';

export const RegistrationGuard: CanActivateFn = () => {
  const
    store = inject(Store),
    router = inject(Router),
    currentUser = store.selectSignal(selectCurrentRsUser)()!;


  return currentUser.access.registration ? true : router.parseUrl('/rs-number');
}
