import { Component, Input } from '@angular/core';
import { Language } from '@lib/rs-ngx';
import { TranslateModule } from '@ngx-translate/core';
import { RsNullDashPipe } from '@rs-shared-lib/pipes/rs-null-dash/rs-null-dash.pipe';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    RsNullDashPipe,
    MatIcon
  ]
})
export class ContactInfoComponent {
  @Input() public name?: string | null;
  @Input() public email?: string | null;
  @Input() public mobileNumber?: string | null;
  @Input() public phoneNumber?: string | null;
  @Input() public language?: Language | null;
}

