import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WallBoxGuard  {

  constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {
  }

  public canActivate(): Observable<boolean> {
    return this.store.select(selectCurrentRsUser)
      .pipe(
        map((currentUser): boolean => {
          if (!currentUser!.access.wallBox) {
            this.router.navigate(['rs-number']);
            return false;
          } else {
            return true;
          }
        })
      );
  }

}
