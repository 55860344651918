/* "Barrel" of Http Interceptors */
import { HttpHeaderInterceptor } from './http-headers.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/** Http interceptor providers in outside-in order */
export const coreInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true
  },
];
