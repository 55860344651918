import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { map } from 'rxjs/operators';


export const DistributionCenterAccessGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectCurrentRsUser)
    .pipe(
      map((currentUser): boolean => {

        if (!currentUser) {
          router.navigate(['rs-number']);
          return false;
        }

        if (currentUser.access.distributionCenter) {
          return true;
        }

        router.navigate(['rs-number']);
        return false;
      })
    );
}


