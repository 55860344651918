import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomMatPaginatorIntlService extends MatPaginatorIntl implements OnDestroy {

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private translateService: TranslateService
  ) {
    super();
    this.getTranslations();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getTranslations(): void {
    this.translateService.stream([
      'PAGINATOR.ITEMS_PER_PAGE',
      'PAGINATOR.NEXT_PAGE',
      'PAGINATOR.PREVIOUS_PAGE',
      'PAGINATOR.RANGE',
      'PAGINATOR.FIRST_PAGE_LABEL',
      'PAGINATOR.LAST_PAGE_LABEL',
      'RANGE_SEPARATOR_LABEL',
      'PAGE'
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translation): void => {
        this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
        this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
        this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE_LABEL'];
        this.lastPageLabel = translation['PAGINATOR.LAST_PAGE_LABEL'];
        this.changes.next();
      });
  }

  /** Display pages not range */
  public override getRangeLabel = (page: number, pageSize: number, length: number): string => {

    if (length === 0 || pageSize === 0) {
      return '';
    }

    length = Math.max(length, 0);

    const currentPage = page;

    const totalPages = Math.ceil(length / pageSize);

    return `${this.translateService.instant('PAGINATOR.PAGE')} ${currentPage + 1} ${this.translateService.instant('PAGINATOR.RANGE_SEPARATOR_LABEL')} ${totalPages}`;
  }
}

