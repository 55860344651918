/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { importProvidersFrom, inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatchFn, ResolveFn, RouterModule, Routes } from '@angular/router';
import { RsNumberGuard } from '@shared/guards/rs-number-guard/rs-number.guard';
import { RegistrationGuard } from '@shared/guards/registration/registration.guard';
import { DistributionCenterAccessGuard } from '@shared/guards/distribution-center/distribution-center-access-guard.service';
import { AdministrationGuard } from '@shared/guards/administration/administration.guard';
import { WallBoxGuard } from '@shared/guards/wall-box/wall-box.guard';
import { OrderGuard } from '@shared/guards/order/order.guard';
import { AdminGuard } from '@shared/guards/admin/admin.guard';
import { Store, StoreModule } from '@ngrx/store';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { catchError, finalize, map } from 'rxjs/operators';
import {
  initialRegistrationsListActiveState,
  registrationsListActiveMetaReducers,
  registrationsListActiveReducer
} from '@app/registrations-v2/overview/active/state/registrations-list-active.reducer';
import {
  initialRegistrationsListInactiveState,
  registrationsListInactiveMetaReducers,
  registrationsListInactiveReducer
} from '@app/registrations-v2/overview/inactive/state/registrations-list-inactive.reducer';
import { RegistrationDetails } from '@app/registrations-v2/details-section/shared/models/registration-details';
import { RsLoaderService, RsMessagesHandlerService } from '@lib/rs-ngx';
import { RegistrationV2Service } from '@app/registrations-v2/details-section/shared/services/registration-v2.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { FrontPlateGuard } from '@shared/guards/front-plate/front-plate.guard';

export const getExistingRegistrationResolver = (
  hideRsLoaderAtResolverLevel?: boolean
): ResolveFn<RegistrationDetails> => (
  route: ActivatedRouteSnapshot
) => {
  const
    rsMessageHandler = inject(RsMessagesHandlerService),
    rsLoaderService = inject(RsLoaderService);

  rsLoaderService.show();

  return inject(RegistrationV2Service)
    .getRegistrationDetails(route.paramMap.get('registrationId')!)
    .pipe(
      catchError((httpError: HttpErrorResponse) => {
        rsMessageHandler.showBeErrorMsg({ httpError })
        return throwError(() => httpError);
      }),
      finalize(() => hideRsLoaderAtResolverLevel && rsLoaderService.hide())
    );
};

const canMatchRegistrationV1: CanMatchFn = () => inject(Store).select(selectCurrentRsUser).pipe(
  map((currentUser) => currentUser?.access.distributionCenterVersion === 'VERSION_1')
);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'orders/active-orders-overview'
  },
  {
    path: 'rs-number',
    pathMatch: 'full',
    loadComponent: () => import('./rs-number-selection/rs-number-selection.component').then(module => module.RsNumberSelectionComponent)
  },
  {
    path: '',
    canActivate: [RsNumberGuard],
    children: [
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(module => module.OrdersModule),
        canActivate: [OrderGuard]
      },
      {
        path: 'registrations',
        canMatch: [canMatchRegistrationV1],
        loadChildren: () => import('./registrations/registrations.module').then(module => module.RegistrationsModule),
        canActivate: [RegistrationGuard]
      },
      {
        path: 'registrations',
        canActivate: [RegistrationGuard],
        children: [
          {
            path: 'active',
            children: [
              {
                path: '',
                loadComponent: () => import('./registrations-v2/overview/active/registrations-list-active.component').then(module => module.RegistrationsListActiveComponent),
                providers: [
                  importProvidersFrom(
                    StoreModule.forFeature(
                      'registrationsListActive',
                      registrationsListActiveReducer,
                      {
                        initialState: initialRegistrationsListActiveState,
                        metaReducers: registrationsListActiveMetaReducers
                      }
                    )
                  )
                ],
              },
              {
                path: 'create',
                children: [
                  {
                    path: '',
                    loadComponent: () => import('./registrations-v2/create/create.component').then(component => component.CreateComponent),
                  },
                  {
                    path: ':orderId',
                    loadComponent: () => import('@app/registrations-v2/prefill/prefill.component').then(component => component.PrefillComponent)
                  }
                ],
              },
            ]
          },
          {
            path: 'inactive',
            children: [
              {
                path: '',
                loadComponent: () => import('./registrations-v2/overview/inactive/registrations-list-inactive.component').then(module => module.RegistrationsListInactiveComponent),
                providers: [
                  importProvidersFrom(
                    StoreModule.forFeature(
                      'registrationsListInactive',
                      registrationsListInactiveReducer,
                      {
                        initialState: initialRegistrationsListInactiveState,
                        metaReducers: registrationsListInactiveMetaReducers
                      }
                    )
                  )
                ],
              },
              {
                path: 'create',
                loadComponent: () => import('./registrations-v2/create/create.component').then(component => component.CreateComponent),
              },
            ]
          },
          {
            path: ':registrationId',
            loadComponent: () => import('./registrations-v2/details-section/sub-menu.component').then(module => module.SubMenuComponent),
            children: [
              {
                path: 'details',
                resolve: {
                  registrationDetails: getExistingRegistrationResolver(true)
                },
                loadComponent: () => import('@app/registrations-v2/details-section/details/details.component').then(component => component.DetailsComponent)
              },
              {
                path: 'edit',
                resolve: {
                  registrationDetails: getExistingRegistrationResolver()
                },
                loadComponent: () => import('@app/registrations-v2/details-section/edit/edit.component').then(component => component.EditComponent)
              },
              {
                path: 'documents',
                loadComponent: () => import('@app/registrations-v2/details-section/documents/registration-details-documents.component').then(m => m.RegistrationDetailsDocumentsComponent)
              },
              {
                path: 'messages',
                loadComponent: () => import('@app/registrations-v2/details-section/messages/registration-details-messages.component').then(m => m.RegistrationDetailsMessagesComponent)
              }
            ]
          },
          {
            path: ':registrationId/package-order',
            loadComponent: () => import('@app/registrations-v2/package-order/package-order.component').then(component => component.PackageOrderComponent)
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'active'
          }
        ]
      },
      {
        path: 'front-plates',
        loadChildren: () => import('./front-plates/front-plates.module').then(module => module.FrontPlatesModule),
        canActivate: [FrontPlateGuard]
      },
      {
        path: 'ev-products',
        loadChildren: () => import('@ev-products/ev-products.routes').then(module => module.EV_PRODUCT_ORDER_ROUTES),
        canActivate: [WallBoxGuard]
      },
      {
        path: 'distribution-center',
        loadChildren: () => import('@distribution-center/distribution-center.module').then(module => module.DistributionCenterModule),
        canActivate: [DistributionCenterAccessGuard]
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule),
        canActivate: [AdministrationGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((module) => module.AdminModule),
        canActivate: [AdminGuard]
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'rs-number'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollOffset: [0, 64],
      useHash: false,
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
