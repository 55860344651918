import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { UserState } from '@app/core/state/user/user.reducer';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class RsNumberGuard  {

  constructor(
    private readonly router: Router,
    private readonly store: Store<UserState>,
  ) {
  }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getCurrentUserFromStore();
  }

  private getCurrentUserFromStore(): Observable<boolean | UrlTree> {
    return this.store
      .select(selectCurrentRsUser)
      .pipe(
        map((currentUser) => currentUser ? true : this.router.parseUrl('/rs-number'))
      );
  }
}
