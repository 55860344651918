import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { RegistrationOverview } from '@app/registrations-v2/shared/models/registration';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { InsuranceBroker } from '@shared/models/insurance-brokers';
import { trimOptionalParams } from '@shared/utils/helpers';
import { Languages } from '@lib/rs-ngx';
import { RegistrationFilters } from '@app/registrations-v2/shared/models/registration-filters';

@Injectable({
  providedIn: 'root',
})
export class RegistrationOverviewService {

  private readonly getInsuranceBrokers$ = this.http.get<InsuranceBroker[]>(`${environment.apiUrl}/insurance-broker`) .pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );

  constructor(private readonly http: HttpClient) {
  }

  public getRegistrations(registrationFilters: RegistrationFilters): Observable<RegistrationOverview[]> {
    return this.http.get<RegistrationOverview[]>(`${environment.apiUrl}/registration`, { params: trimOptionalParams(registrationFilters) });
  }

  public exportRegistrations(registrationFilters: RegistrationFilters, language: Languages): Observable<HttpResponse<Blob>> {
    const optionalParams = trimOptionalParams(registrationFilters);
    optionalParams['language'] = language;

    return this.http.get<HttpResponse<Blob>>(
      `${environment.apiUrl}/registration/export`,
      {
        params: optionalParams,
        observe: 'response' as 'body',
        responseType: 'blob' as 'json'
      }
    );
  }

  public getInsuranceBrokers(): Observable<InsuranceBroker[]> {
    return this.getInsuranceBrokers$;
  }

  public getPageCount(registrationFilters: RegistrationFilters): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/registration/pagecount`, { params: trimOptionalParams(registrationFilters) });
  }

}
