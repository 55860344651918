import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { GetPackageOrdersQueryParams } from '@distribution-center/package-orders/shared/models/get-package-orders-query-params';
import { RsFile, RsFormatPaginatedListRequestBody, RsUtils } from '@lib/rs-ngx';
import { environment } from '@environments/environment';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';
import { ExternalInfo, PackageOrderBasic } from '@distribution-center/package-orders/shared/models/package-order-basic';
import { Observable } from 'rxjs';
import { PackageOrderDetails, UpdatePackageOrder } from '@distribution-center/package-orders/details/models/package-order-details';
import { CreatePackageOrder } from '@distribution-center/package-orders/shared/models/create-package-order';
import { UUID } from '@rs-shared-lib/models/custom-types';
import { map } from 'rxjs/operators';
import { CreateRegistrationPackageOrder } from '@app/registrations-v2/shared/models/create-registration-package-order';

@Injectable()
export class PackageOrdersService {

  constructor(private readonly http: HttpClient) {
  }

  public readonly getPackageOrderDetails = (id: UUID): Observable<PackageOrderDetails> => {
    return this.http.get<PackageOrderDetails>(
      `${environment.apiGatewayUrl}/distribution-center/package-order/${id}`
    );
  };

  public readonly updatePackageOrder = (updateBody: UpdatePackageOrder, orderId: UUID, filesToAdd: RsFile[]): Observable<PackageOrderDetails> => {
    const formData = this.updatePackageOrderFormData(updateBody, filesToAdd);
    return this.http.put<PackageOrderDetails>(
      `${environment.apiGatewayUrl}/distribution-center/package-order/${orderId}`,
      formData,
    );
  };

  public readonly getPackageOrders = (queryParams: GetPackageOrdersQueryParams): Observable<RsFormatPaginatedListRequestBody<PackageOrderBasic[]>> => {
    const params = RsUtils.trimEmptyHttpParams(queryParams);

    return this.http
      .get<PackageOrderBasic[]>(
        `${environment.apiGatewayUrl}/distribution-center/package-order`,
        { observe: 'response', params }
      ).pipe(
        rsFormatPaginatedListRequestBody()
      );
  };

  public readonly createPackageOrder = (packageOrder: CreatePackageOrder, files: RsFile[] | null): Observable<PackageOrderDetails> => {
    const formData = createPackageOrderFormData(packageOrder, files);
    return this.http.post<PackageOrderDetails>(
      `${environment.apiGatewayUrl}/distribution-center/package-order`,
      formData
    );
  };

  public readonly getPackageOrderDocument = (packageOrderId: UUID, documentId: UUID): Observable<HttpResponse<Blob>> => {
    return this.http.get<Blob>(
      `${environment.apiGatewayUrl}/distribution-center/package-order/${packageOrderId}/document/${documentId}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json'
      }
    );
  };

  public readonly getExternalInfo = (packageOrderId: string): Observable<ExternalInfo> => {
    return this.http.get<ExternalInfo>(
      `${environment.apiGatewayUrl}/distribution-center/package-order/${packageOrderId}/external-info`,
    );
  };

  public readonly getPackageOrdersExport = (queryParams: Omit<GetPackageOrdersQueryParams, '_page' | '_pageSize'>): Observable<{
    fileName: string;
    blob: Blob
  }> => {
    const params = RsUtils.trimEmptyHttpParams(queryParams);

    return this.http.get<Blob>(
      `${environment.apiGatewayUrl}/distribution-center/package-order/export`,
      {
        params: new HttpParams({ fromObject: params }),
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    ).pipe(
      map(({
        body, headers,
      }) => ({
        fileName: headers.get('Content-Disposition')?.split('filename=')[1] ?? 'Export',
        blob: new Blob(
          [body!],
          { type: headers.get('Content-Type')! },
        ),
      })),
    );
  };

  private updatePackageOrderFormData(updatePackageOrder: UpdatePackageOrder, files: RsFile[]): FormData {
    const formData = new FormData();

    formData.append(
      'packageOrder',
      new Blob([JSON.stringify(updatePackageOrder)], { type: 'application/json' }),
    );

    if (files) {
      const filesData = files.map(file => file.data);
      filesData.forEach(fileData => {
        formData.append('documentsToAdd', fileData, fileData.name);
      });
    }

    return formData;
  }
}


export const createPackageOrderFormData = (packageOrder: CreateRegistrationPackageOrder, files: RsFile[] | null): FormData => {
  const formData = new FormData();

  formData.append(
    'packageOrder',
    new Blob([JSON.stringify(packageOrder.packageOrder)], { type: 'application/json' })
  );

  if (files) {
    const filesData = files.map(file => file.data);
    filesData.forEach(fileData => {
      formData.append('documents', fileData, fileData.name);
    });
  }

  return formData;
}
