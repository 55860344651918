<div class='rs-card contact'>
  <h3>{{ 'CONTACT' | translate }}</h3>

  <div class='row'>
    <div
      data-testid="contact-info-name"
      class='col-sm-12 col-md-6'
    >
      {{ name | rsNullDash }}
    </div>
    <div
      data-testid="contact-info-email"
      class='col-sm-12 col-md-6'
    >
      <mat-icon>email</mat-icon>
      {{ email | rsNullDash }}
    </div>
  </div>

  <div class='row'>
    <div class='col-sm-12 col-md-6'></div>
    <div
      data-testid="contact-info-mobile-number"
      class='col-sm-12 col-md-6'
    >
      <mat-icon>smartphone</mat-icon>
      {{ mobileNumber | rsNullDash }}
    </div>
  </div>

  <div class='row'>
    <div class='col-sm-12 col-md-6'></div>
    <div
      data-testid="contact-info-phone-number"
      class='col-sm-12 col-md-6'
    >
      <mat-icon>phone</mat-icon>
      {{ phoneNumber | rsNullDash }}
    </div>
  </div>

  <div class='row'>
    <div class='col-sm-12 col-md-6'></div>
    <div
      data-testid="contact-info-language"
      class='col-sm-12 col-md-6'
    >
      <mat-icon>chat_bubble_outline</mat-icon>
      {{ 'LANGUAGES.' + language | translate | rsNullDash: '' : '' : { separatorsToStrip: ['LANGUAGES.'] } }}
    </div>
  </div>

</div>