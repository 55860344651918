import * as moment from 'moment';
import { isDate, isMoment, Moment, MomentInput } from 'moment';
import { cloneDeep, isArray, isObject } from 'lodash';
import { RsObjectLiteral } from '../models/custom-types';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Recursively formats Moment or Date properties in the given object to string properties (using the specified format).
 *
 * @template T - The type of the input object.
 * @param {T} objectToFormat - The object to be formatted.
 * @param {string} [format='YYYY-MM-DD'] - Optionally, the pattern the Moment/Date properties should be formatted to.
 * @returns {FormattedDates<T>} - An object with formatted date properties.
 */
export function recursiveDateFormatter<T extends RsObjectLiteral>(objectToFormat: T, format: string = DEFAULT_DATE_FORMAT): FormattedDates<T> {
  const obj = cloneDeep(objectToFormat);
  const keys = Object.keys(obj) as (keyof typeof obj)[];


  for (const property of keys) {
    if (isMoment(obj[property]) || isDate(obj[property])) {
      obj[property] = moment(obj[property] as MomentInput).format(format) as T[keyof T];
    } else if (isObject(obj[property]) && !isArray(obj[property])) {
      obj[property] = recursiveDateFormatter(obj[property]) as T[keyof T];
    }
  }

  return obj as FormattedDates<T>;
}

export type FormattedDates<T> = {
  [K in keyof T]: T[K] extends Moment | Date ? string : T[K];
};
