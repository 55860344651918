import { clearExistingRegistration, loadInsuranceBrokersSuccess, saveRegistrationID, saveRegistrationOrderID, setBackToOverviewLink } from './registrations.actions';
import { UUID } from '@lib/rs-ngx';
import { createReducer, on } from '@ngrx/store';
import { InsuranceBroker } from '@shared/models/insurance-brokers';
import { produce } from 'immer';

export interface RegistrationsState {
  retrieveRegistrationData: {
    existingRegistration: boolean;
    id: UUID;
  } | null;
  backToOverviewLink: 'registrations/active-registrations-overview' | 'registrations/inactive-registrations-overview';
  insuranceBrokers: InsuranceBroker[];
}

export const initialRegistrationsState: RegistrationsState = {
  retrieveRegistrationData: null,
  backToOverviewLink: 'registrations/active-registrations-overview',
  insuranceBrokers: []
};

export const registrationsReducer = createReducer(
  initialRegistrationsState,
  on(saveRegistrationID, (state, { id }) =>
    produce(state, draft => {
      draft.retrieveRegistrationData = {
        existingRegistration: true,
        id: id,
      };
    })),

  on(saveRegistrationOrderID, (state, { id }) =>
    produce(state, draft => {
      draft.retrieveRegistrationData = {
        existingRegistration: false,
        id: id,
      };
    })),

  on(clearExistingRegistration, (state) =>
    produce(state, draft => {
      draft.retrieveRegistrationData = null;
    })),

  on(setBackToOverviewLink, (state, { link }) =>
    produce(state, draft => {
      draft.backToOverviewLink = link;
    })),

  on(loadInsuranceBrokersSuccess, (state, { insuranceBrokers }) =>
    produce(state, draft => {
      draft.insuranceBrokers = insuranceBrokers;
    })),
);
