import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { Store } from '@ngrx/store';

export const OrderGuard: CanActivateFn = () => {
  const
    store = inject(Store),
    router = inject(Router),
    currentUser = store.selectSignal(selectCurrentRsUser)()!,
    redirectUserToEvProducts = currentUser.userRole === 'DEALER' && currentUser.access.wallBox;

  return redirectUserToEvProducts ? router.parseUrl('/ev-products') : true;
}
