import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { upperFirst } from 'lodash';
import { isUUID } from '@shared/utils/helpers';

@Injectable({ providedIn: 'root' })
export class OrdTitleStrategy extends TitleStrategy {

  private readonly title = inject(Title);
  private readonly appName = 'ORD';

  public override updateTitle(snapshot: RouterStateSnapshot): void {
    this.title.setTitle(this.buildTitle(snapshot));
  }

  public override buildTitle(snapshot: RouterStateSnapshot): string {
    let currentRoute = snapshot.root;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    let path = snapshot.url.split('/').splice(1)
      .map((pathname) => {

        if (isUUID(pathname)) {
          return pathname;
        }

        return upperFirst(pathname.replace(/-/g, ' '));

      }).join(' | ');

    if (currentRoute.data.resolvedData?.leasingCompanyDossierNumber) {
      path = path.replace(snapshot.url.split('/')[2], `${currentRoute.data.resolvedData.leasingCompanyDossierNumber}`)
    }

    return `${this.appName} | ${this.trimAnchors(path)}`;
  }

  private trimAnchors(str: string): string {
    const anchorIndex = str.indexOf('#');
    return anchorIndex > 0 ? str.slice(0, anchorIndex) : str;
  }
}
