import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { RsFormatPaginatedListRequestBody } from '../../models';

/** **Will Provide a generic response for single Paginated list request**
 *
 *
 * @description This custom pipe was made to be used **ONLY** for single Paginated list request providing resultcount header and returning body: Type[].
 *
 *
 * Usage Example:
 * ```
 *      .pipe(
 *         rsFormatPaginatedListRequestBody()
 *       )
 *       .subscribe(overviewPage => {
 *         console.log(overviewPage.results);
 *         console.log(overviewPage.totalResults);
 *       });
 * ```
 *
 *  @return Observable {
 *    results: Type[],
 *    totalResults: number
 *  }
 *
 *
 **/
export function rsFormatPaginatedListRequestBody<T>(): OperatorFunction<HttpResponse<T[]>, RsFormatPaginatedListRequestBody<T[]>> {
  return (source$: Observable<HttpResponse<T[]>>): Observable<RsFormatPaginatedListRequestBody<T[]>> => {
    return source$.pipe(
      map((res): RsFormatPaginatedListRequestBody<T[]> => ({
        results: res.body ?? [],
        totalResults: Number(res.headers.get('resultcount')),
        pageCount: Number(res.headers.get('pagecount')),
      }))
    );
  };
}
