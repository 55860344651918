import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';
import { environment } from '@environments/environment';
import { TranslationService } from '@lib/rs-ngx';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  private static readonly CGK_API_URLS = [
    environment.apiGatewayUrl,
    environment.apiUrl,
    environment.distributionCenterApi,
  ];
  private readonly userName$: Observable<string | undefined>;

  public constructor(
    private readonly translationService: TranslationService,
    store: Store,
  ) {
    this.userName$ = store.select(selectCurrentRsUser).pipe(
      first(),
      map(user => user?.userName),
    );
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.requestToCegekaApi(request.url)) {
      return this.handle(next, request);
    }
    return next.handle(request);
  }

  private handle(next: HttpHandler, request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    return this.userName$.pipe(mergeMap(userName => {
      if (!userName) {
        return next.handle(request);
      }

      const requestWithHeaders = request.clone({
        setHeaders: {
          'X-WSS-rs-user-number': userName,
          'Accept-Language': this.translationService.currentLanguage.short,
        },
      });
      return next.handle(requestWithHeaders);
    }));
  }

  private requestToCegekaApi(requestUrl: string): boolean {
    return HttpHeaderInterceptor.CGK_API_URLS
      .some(url => requestUrl.includes(url));
  }
}
